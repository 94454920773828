<template>
  <BCard class="min-h-[100vh]">
    <div class="flex w-full justify-between items-center">
      <div class="flex gap-[12px]">
        <BButton
          variant="primary"
          size="sm"
          class="rounded-[6px]"
          @click="$router.back()"
        >
          <img
            class="w-[20px]"
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/arrow-left-2.svg"
            alt="arrow left"
          >
        </BButton>
        <div class="text-[#333333] font-semibold text-[24px]">
          Mutasi
        </div>
      </div>
      <BButton
        variant="outline-primary"
        class="!flex !items-center !gap-2 !rounded-lg"
        size="sm"
        @click="$bvModal.show('download-mutation')"
      >
        <img
          class="w-[20px]"
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-download.svg"
          alt="arrow left"
        >
        <div>Download Mutasi</div>
      </BButton>
    </div>
    <div class="flex items-center justify-between mt-[32px] border-t-[#E2E2E2] border-t-[1px]">
      <div class="flex justify-center mt-[16px] items-center mb-[16px]">
        <div
          v-if="detailPartner"
          class="flex justify-center gap-[8px] items-center w-fit px-[24px] py-[8px] bg-[#F8F8F8] rounded-[8px]"
        >
          <span class="k-card text-[#C2C2C2] text-[20px]" />
          <div class="text-nowrap font-semibold text-[16px] text-[#333333]">
            {{ detailPartner.label }}
          </div>
          <div class="text-[#626262] text-[14px]">
            ***{{ detailPartner.info.card_number }}
          </div>
        </div>
        <div class="ml-1 text-black">
          <span class="text-[#828282]">Created</span> {{ DDMMMMYYYY(detailPartner.created_at) }}
        </div>
      </div>
      <div class="flex gap-[12px] items-center">
        <DateRangePicker
          ref="picker"
          v-model="dateRange"
          :locale-data="locale"
          :ranges="ranges"
          :opens="'left'"
          class="withdrawal-date-range mr-1"
          style="min-width: 170px !important"
        >
          <template v-slot:input="picker">
            <div>
              {{ DDMMYYYY(picker.startDate) }} -
              {{ DDMMYYYY(picker.endDate) }}
              <span
                alt="Calendar"
                class="h-5 ml-1 k-calendar"
              />
            </div>
          </template>
          <div
            slot="ranges"
            slot-scope="data"
          >
            <div
              v-for="(range, name) in data.ranges"
              :key="range"
            >
              <div
                v-if="name !== 'Custom Range'"
                :class="
                  `${DATE_NUMBER_SLASH(data.startDate)} - ${DATE_NUMBER_SLASH(
                    data.endDate
                  )}` ===
                    `${DATE_NUMBER_SLASH(range[0])} - ${DATE_NUMBER_SLASH(
                      range[1]
                    )}`
                    ? 'text-white bg-[#F95031]'
                    : 'text-black'
                "
                class="px-[14px] w-full py-[8px] text-[14px] cursor-pointer"
                @click="data.clickRange(range)"
              >
                {{ name }}
              </div>
            </div>

            <div
              v-for="(range, name) in data.ranges"
              :key="name"
            >
              <div
                v-if="name === 'Custom Range'"
                :class="
                  isCustomRange(data.ranges, data)
                    ? 'text-white bg-[#F95031]'
                    : 'text-black'
                "
                class="px-[14px] w-full py-[8px] text-[14px] cursor-pointer"
                @click="data.clickRange(range)"
              >
                {{ name }}
              </div>
            </div>
          </div>
          <div
            slot="footer"
            slot-scope="data"
          >
            <div
              class="border-top border-[1px] flex justify-end px-[10px] py-[15px] items-center"
            >
              <div class="flex gap-[10px]">
                <div
                  class="text-[#F95031] cursor-pointer text-[12px] rounded-sm py-[5px] px-[8px] font-semibold border-[1px] border-[#F95031]"
                  @click="handleResetDatePicker"
                >
                  Reset
                </div>
                <div
                  :class="
                    `${DATE_NUMBER_SLASH(
                      dateRange.startDate
                    )} - ${DATE_NUMBER_SLASH(dateRange.endDate)}` ===
                      data.rangeText
                      ? 'text-[#C2C2C2] border-[#C2C2C2] bg-[#F4F4F4]'
                      : 'cursor-pointer text-white bg-[#F95031]'
                  "
                  class=" text-[12px] rounded-sm py-[5px] px-[8px] font-semibold border-[1px]"
                  @click="
                    ;`${DATE_NUMBER_SLASH(
                      dateRange.startDate
                    )} - ${DATE_NUMBER_SLASH(dateRange.endDate)}` !==
                      data.rangeText && handleClickDatePicker(data)
                  "
                >
                  Submit
                </div>
              </div>
            </div>
          </div>
        </DateRangePicker>
        <div class="x-[10px] my-1">
          <BDropdown
            id="dropdown-1"
            no-caret
            size="sm"
            toggle-class="toggle-custom"
            variant="primary"
            right
          >
            <template #button-content>
              <div class="relative">
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
                  alt="filter"
                >
                <div
                  v-if="
                    state_status.length > 0 || state_transaction_type.length > 0
                  "
                  class="absolute -top-3 right-[25px] h-[25px] flex justify-center items-center w-[25px] text-[14px] rounded-full bg-primary"
                >
                  {{
                    (state_status.length > 0 ? 1 : 0) +
                      (state_transaction_type.length > 0 ? 1 : 0)
                  }}
                </div>
              </div>
            </template>
            <div
              class="text-[#333333] my-1 font-semibold text-[14px] px-[10px] min-w-[250px]"
            >
              Filter
            </div>
            <hr>
            <div class="x-[10px] my-1 px-1">
              <label class="mt-1">Jenis Transaksi</label>
              <VSelect
                v-model="transaction_type"
                :options="options_transaction_type"
                :reduce="option => option.value"
                label="name"
                :clearable="false"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
              <label class="mt-1">Status Transaksi</label>
              <VSelect
                v-model="status"
                :options="options_status"
                :reduce="option => option.value"
                label="name"
                :clearable="false"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
            </div>
            <hr>
            <div class="px-[10px] my-1 grid grid-cols-2 gap-[4px]">
              <BButton
                variant="outline-primary"
                @click="handleReset"
              >
                Reset
              </BButton>
              <BButton
                variant="primary"
                @click="handleSubmit"
              >
                Terapkan
              </BButton>
            </div>
          </BDropdown>
        </div>
      </div>
    </div>
    <BOverlay
      :show="loading.table"
      spinner-variant="primary"
      variant="light"
      class="mt-[16px]"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        :class="'infinite-scroll'"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data yang ditampilkan"
          responsive
        >
          <template #cell(description)="data">
            <div class="flex flex-column gap-[5px]">
              <span class="text-[#333333] font-semibold">
                {{ data.item.description }}
              </span>
              <span v-if="data.item.is_kompoints === false" class="text-[#828282]">{{ data.item.transaction_uuid }}</span>
            </div>
          </template>
          <template #cell(status)="data">
            <div
              class="flex justify-center"
              :class="statusClass(data.item.status)"
            >
              <div
                v-if="data.item.status === 'success'"
                class="flex items-center justify-start"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/tick-circle.svg"
                  alt="Komerce"
                  width="20"
                >
                <div class="ml-[5px]">
                  Berhasil
                </div>
              </div>
              <div
                v-if="data.item.status === 'failed'"
                class="flex items-center justify-start"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/close-circle.svg"
                  alt="Komerce"
                  width="20"
                >
                <div class="ml-[5px]">
                  Gagal
                </div>
              </div>
              <div
                v-if="data.item.status === 'pending'"
                class="flex items-center justify-start"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/info-circle.svg"
                  alt="Komerce"
                  width="20"
                >
                <div class="ml-[5px]">
                  Pending
                </div>
              </div>
            </div>
          </template>
          <template #cell(date)="data">
            {{ FULL_DATE_TIME(data.item.date) }}
          </template>
          <template #cell(card)="data">
            <div class="flex items-center justify-start">
              <BImg
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card-pos.svg"
              />
              <div class="ml-[5px]">
                {{ data.item.card.card_label }}
              </div>
            </div>
          </template>
          <template #cell(amount)="data">
            <div
              v-if="data.item.is_kompoints"
              class="flex justify-center items-center gap-3"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
                alt="Komerce"
                width="20"
              >
              <div>{{ data.item.amount }}</div>
            </div>
            <div
              v-else
              :class="nominalClass(data.item.transaction_type)"
            >
              <span
                v-if="data.item.transaction_type === 'credit'"
              >+{{ IDR(data.item.amount, 2, 2) }}</span>
              <div v-if="data.item.transaction_type === 'debit'">
                -{{ IDR(data.item.amount, 2, 2) }}
              </div>
              <div
                v-if="data.item.transaction_type === 'cashback'"
                class="flex items-center justify-center"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
                  alt="Komerce"
                  width="20"
                >
                <div class="ml-[5px]">
                  {{ IDR(data.item.amount, 2, 2) }}
                </div>
              </div>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <DownloadMutation />
  </BCard>
</template>

<script>
import {
  DAY_MONTH_YEAR,
  LABELMONTH,
  YEAR_MONTH,
  YEAR_MONTH_DAY,
  DDMMYYYY,
  LABELDAY,
  FULL_DATE_TIME,
  DAY_MONTH_YEAR_SLASH,
  DATE_NUMBER_SLASH,
  DDMMMMYYYY,
} from '@/libs/filterDate'
import {
  firstDateOfMonth,
  lastDateOfLastMonth,
  lastMonth,
  today,
} from '@/store/helpers'
import DateRangePicker from 'vue2-daterange-picker'
import { IDR } from '@/libs/currency'
import { newAxiosIns } from '@/libs/axios'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import DownloadMutation from './DownloadMutation.vue'

import columnConfig from './config'

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: { DateRangePicker, DownloadMutation, VSelect },
  data() {
    return {
      // start: calendar filter
      DAY_MONTH_YEAR,
      DAY_MONTH_YEAR_SLASH,
      DATE_NUMBER_SLASH,
      LABELMONTH,
      YEAR_MONTH,
      YEAR_MONTH_DAY,
      DDMMYYYY,
      LABELDAY,
      FULL_DATE_TIME,
      DDMMMMYYYY,
      firstDateOfMonth,
      lastDateOfLastMonth,
      ranges: {
        'Bulan Ini': [firstDateOfMonth, today],
        'Bulan Lalu': [lastMonth(1), lastDateOfLastMonth],
        '2 Bulan Terakhir': [lastMonth(2), lastDateOfLastMonth],
        '3 Bulan Terakhir': [lastMonth(3), lastDateOfLastMonth],
        'Custom Range': [today, today],
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      dateRange: {
        startDate: firstDateOfMonth,
        endDate: today,
      },
      // end
      state_transaction_type: [],
      transaction_type: '',
      options_transaction_type: [
        { name: 'Semua', value: '' },
        { name: 'Tranksaksi Masuk', value: 'credit' },
        { name: 'Tranksaksi Keluar', value: 'debit' },
        { name: 'Cashback', value: 'cashback' },
      ],
      state_status: [],
      status: '',
      options_status: [
        { name: 'Semua', value: '' },
        { name: 'Berhasil', value: 'success' },
        { name: 'Pending', value: 'pending' },
        { name: 'Gagal', value: 'failed' },
      ],
      limit: 25,
      offset: 0,
      lastData: false,
      fields: columnConfig,
      IDR,
      items: [],
      detailPartner: null,
      loading: {
        table: false,
        sync: false,
        detail: false,
      },
    }
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-1') {
        this.status = this.state_status
        this.transaction_type = this.state_transaction_type
      }
    })
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })

    this.getListMutation()
    this.getDetailCard()
  },
  methods: {
    handleClickDatePicker(data) {
      this.offset = 0
      data.clickApply()
      this.getListMutation()
    },
    handleResetDatePicker() {
      this.offset = 0
      this.dateRange = {
        startDate: firstDateOfMonth,
        endDate: today,
      }
      this.getListMutation()
    },
    async getListMutation() {
      this.loading.table = true

      const url = 'komcards/api/v1/card/mutation'
      const { id } = this.$route.params

      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
        transaction_type: this.state_transaction_type,
        status: this.state_status,
        limit: this.limit,
        card_id: id,
        offset: this.offset,
      }
      try {
        const respond = await newAxiosIns.request({
          method: 'get',
          url,
          params,
        })

        const { data } = respond.data
        this.items = data

        if (data.length < this.limit) {
          this.lastData = true
        } else {
          this.offset += data.length
          this.lastData = false
        }

        this.loading.table = false
      } catch (error) {
        this.$toast_error({ message: 'Gagal load data' })
        this.loading.table = false
      }
    },
    async getDetailCard() {
      const { id } = this.$route.params
      const url = `komcards/api/v1/card/${id}/detail`
      try {
        const response = await newAxiosIns.request({
          method: 'get',
          url,
        })

        this.detailPartner = response.data.data
        return
      } catch (error) {
        this.$toast_error({ message: 'Gagal load data' })
        this.loading.detail = false
      }
    },
    async getNextDataMutation() {
      const { id } = this.$route.params

      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
        transaction_type: this.state_transaction_type,
        status: this.state_status,
        card_id: id,
        limit: this.limit,
        offset: this.offset,
      }
      const url = 'komcards/api/v1/card/mutation'

      this.loading.table = true

      try {
        const response = await newAxiosIns.request({
          method: 'get',
          params,
          url,
        })

        const { data } = response.data

        this.items.push(...data)
        this.offset += data.length
        if (data.length < this.limit) {
          this.lastData = true
        }

        this.loading.table = false
        return
      } catch (error) {
        this.$toast_error({ message: 'Gagal load data' })
        this.loading.table = false
      }
    },
    handleReset() {
      this.state_status = []
      this.state_transaction_type = []
      this.status = []
      this.transaction_type = []
      this.offset = 0
      this.getListMutation()
    },
    handleSubmit() {
      this.state_transaction_type = this.transaction_type
      this.state_status = this.status

      this.offset = 0
      this.getListMutation()
    },
    handleScroll() {
      const table = document.getElementById('table')

      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loading.table
        && !this.lastData
      ) {
        this.getNextDataMutation()
      }
    },
    nominalClass(type) {
      if (type === 'credit') {
        return 'text-[#34A770]'
      }
      if (type === 'debit') {
        return 'text-[#E31A1A]'
      }
      if (type === 'cashback') {
        return 'text-[#CC9212]'
      }
      return ''
    },
    statusClass(status) {
      if (status === 'success') {
        return 'text-[#34A770]'
      }
      if (status === 'failed') {
        return 'text-[#E31A1A]'
      }
      if (status === 'pending') {
        return 'text-[#828282]'
      }
      return ''
    },
    isCustomRange(range, data) {
      return !Object.values(
        Object.fromEntries(
          Object.entries(range).filter(([key]) => key !== 'Custom Range'),
        ),
      ).some(
        e => `${DATE_NUMBER_SLASH(data.startDate)} - ${DATE_NUMBER_SLASH(
          data.endDate,
        )}` === `${DATE_NUMBER_SLASH(e[0])} - ${DATE_NUMBER_SLASH(e[1])}`,
      )
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
.infinite-scroll {
  height: calc(100vh - 250px);
  overflow-y: scroll;
}
.list_transaction >>> .custom-control-label {
  color: #626262;
}
</style>
